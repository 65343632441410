<template>
    <div class="tab-text">
        <div class="vx-row mb-12">
            <div v-bind:class="[
                mutualAction == 'edit' || mutualAction == 'create' ? detailShow : '',
                detailHide,
            ]">
                <data-table :baseUrl="baseUrl" @id="setId" @action="setAction" :action="mutualAction"
                    :status="11"></data-table>
            </div>
        </div>
    </div>
</template>
<script>
import DataTable from '../DataTable.vue'
export default {
    mounted() {
    },
    components: {
        DataTable
    },
    data() {
        return {
            title: "Work Order",
            baseUrl: "api/wms/v1/work-order",
            detailShow: "core vx-col md:w-1/2 w-full mb-base ",
            detailHide: "core vx-col md:w-1/1 w-full mb-base ",
            mutualAction: "",
            id: null,
        }
    },
    methods: {
        handleClose() {
            window.scrollTo(0, 0);
            this.mutualAction = "";
        },
        setId(val) {
            this.id = val;
        },
        setAction(val) {
            this.mutualAction = val;
        },
    },
}
</script>

<style scoped>
.core-enter-active {
    transition: all 0.3s ease;
}

.core-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
    {
    transform: translateX(10px);
    opacity: 0;
}

.core {
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
    font-size: 12px;
}

.vertical-divider {
    border-left: 1px solid #7367f0;
    /* min-height: 800px; */
    padding: 5px;
}
</style>