var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-text"},[_c('div',{staticClass:"vx-row mb-12"},[_c('div',{class:[
      _vm.mutualAction == 'edit' || _vm.mutualAction == 'create' ? _vm.detailShow : '',
      _vm.detailHide,
    ]},[_c('data-table',{attrs:{"baseUrl":_vm.baseUrl,"action":_vm.mutualAction,"status":12},on:{"id":_vm.setId,"action":_vm.setAction}})],1),_c('transition',{attrs:{"name":"detail-fade"}},[(_vm.mutualAction == 'edit' || _vm.mutualAction == 'create')?_c('div',{class:[
        _vm.mutualAction == 'edit' || _vm.mutualAction == 'create'
          ? _vm.detailShow
          : '',
        _vm.detailHide,
      ]},[_c('vs-row',[_c('vs-col',{staticStyle:{"padding-bottom":"8px"},attrs:{"vs-offset":"8","vs-type":"flex","vs-justify":"rigth","vs-align":"rigth","vs-w":"4"}},[_c('vs-button',{staticClass:"ml-auto",attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.handleClose}},[_vm._v("Close")])],1),_c('vs-col',{staticClass:"vertical-divider",attrs:{"vs-offset":"0","vs-type":"flex","vs-justify":"center","vs-align":"center","vs-w":"1"}},[_c('vs-button',{attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.handleClose}})],1),(_vm.mutualAction == 'edit' || _vm.mutualAction == 'create')?_c('data-form',{attrs:{"id":_vm.id,"baseUrl":_vm.baseUrl,"title":_vm.title},on:{"action":_vm.setAction,"close":_vm.handleClose}}):_vm._e()],1)],1):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }