<template>
    <div>
        <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div
                        class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total
                            }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>
            <template slot="thead">
                <vs-th width="10%"></vs-th>
                <vs-th sort-key="code">Work Order ID </vs-th>
                <vs-th sort-key="warehouse_name">Warehouse</vs-th>
                <vs-th sort-key="request_date">Request Date</vs-th>
                <vs-th sort-key="execute_date">Execute Date</vs-th>
                <vs-th sort-key="item_bom_code">BoM ID </vs-th>
                <vs-th sort-key="name">Parent SKU Data </vs-th>
                <vs-th sort-key="name">Child SKU Data </vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <div class="vx-input-group flex">
                            <vs-button size="small" color="warning" icon-pack="feather" icon="icon-edit" title="Edit"
                                v-if="status == 1 || status == 11" style="margin-right: 5px"
                                @click="handleEdit(tr.id)" />
                            <vs-button size="small" color="warning" icon-pack="feather" icon="icon-eye"
                                v-if="status != 1 && status != 11 && status != 12" title="Detail"
                                style="margin-right: 5px" @click="handleShow(tr.id)" />
                            <vs-button size="small" color="success" icon-pack="feather" icon="icon-check"
                                title="Confirm" v-if="status == 1 || status == 11" style="margin-right: 5px"
                                @click="handleApprove(tr.id)" />
                            <vs-button size="small" color="danger" icon-pack="feather" icon="icon-x" title="Cancel"
                                v-if="status == 1" @click="handleReject(tr.id)" />
                            <vs-button size="small" color="primary" icon-pack="feather" icon="icon-file" title="Attach"
                                v-if="status == 12" @click="handleAttach(tr.id)" />
                        </div>
                    </vs-td>
                    <vs-td :data="data[indextr].code">{{ data[indextr].code }}</vs-td>
                    <vs-td :data="data[indextr].warehouse_name">{{
                        data[indextr].warehouse_name ? data[indextr].warehouse_name : ""
                        }}</vs-td>
                    <vs-td :data="data[indextr].request_date">{{
                        data[indextr].request_date
                            ? $options.filters.formatDate(data[indextr].request_date)
                            : ""
                    }}</vs-td>
                    <vs-td :data="data[indextr].execution_date">{{
                        data[indextr].execution_date
                            ? $options.filters.formatDate(data[indextr].execution_date)
                            : ""
                    }}</vs-td>
                    <vs-td :data="data[indextr].item_bom_code">{{
                        data[indextr].item_bom_code ? data[indextr].item_bom_code : ""
                        }}</vs-td>
                    <vs-td :data="data[indextr].name">{{
                        data[indextr].name ? data[indextr].name : ""
                        }}</vs-td>
                    <vs-td :data="data[indextr].work_order_lines">
                        <ul>
                            <li :data="tr" :key="indextr" v-for="(tr, indextr) in data[indextr].work_order_lines">
                                {{ tr.name }}
                            </li>
                        </ul>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>

<script>
import moment from "moment";
export default {
    props: {
        baseUrl: {
            type: String,
        },
        title: {
            type: String,
        },
        action: {
            type: String,
        },
        status: {
            type: Number,
        },
        workOrderID: {
            type: String,
        },
        date: {
            type: String,
        },
        isFilter: {
            type: Boolean,
        },
    },
    data() {
        return {
            table: this.tableState(),
            mutableAction: "",
            filter: this.isFilter,
        };
    },
    methods: {
        tableState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "desc",
                sort: "ID",
                total: 0,
                totalPage: 0,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            if (active) {
                this.table.order = key;
                this.table.sort = active;
            } else {
                this.table.order = "ID";
                this.table.sort = "desc";
            }
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        getData() {
            this.$vs.loading();
            this.$http.get(this.baseUrl + "/list", {
                params: {
                    length: this.table.length,
                    page: this.table.page,
                    search: this.table.search,
                    order: this.table.order,
                    sort: this.table.sort,
                    status: Number(this.status),
                    work_order_id: this.workOrderID ? this.workOrderID : null,
                    request_date: this.date && this.filter ? this.date : null,
                },
            })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.table.total = resp.data.total_record;
                        this.table.totalPage = resp.data.total_page;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.total_record_per_page;
                        this.table.data = resp.data.records;
                        this.$emit("isFilter", false);
                        console.log(">>>>>>>>>", this.table.data);
                        this.setStartEnd()
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        setStartEnd() {
            let valStart = this.table.length * this.table.page - this.table.length + 1;
            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;
            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }
            if (this.table.totalSearch < this.table.total &
                this.table.search != "") {
                valEnd = this.table.totalSearch;
            }
            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleEdit(id) {
            this.$router.push({
                name: "work-order-ho-edit",
                params: { id: id },
            });
        },
        handleShow(id) {
            if (this.status == 10) {
                this.$router.push({
                    name: "work-order-execution-complete-detail",
                    params: { id: id },
                });
            } else {
                this.$router.push({
                    name: "work-order-ho-show",
                    params: { id: id },
                });
            }
        },
        handleAttach(id) {
            this.$router.push({
                name: "work-order-ho-attach",
                params: { id: id },
            });
        },
        handleApprove(id) {
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to send approval for this data?",
                accept: () => {
                    this.$vs.loading();
                    let st;
                    if (this.status == 1 || this.status == 11) {
                        st = 2;
                    } else if (this.status == 3) {
                        st = 4;
                    }
                    this.$http.post("api/wms/v1/work-order/update-status", {
                        id: id,
                        status: st,
                    })
                        .then((resp) => {
                            this.$vs.loading.close();
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: "The data was successfully send to approval",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            } else {
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }
                            this.getData();
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                }
            });
        },
        handleReject(id) {
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to reject this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http.post("api/wms/v1/work-order/update-status", {
                        id: id,
                        status: 7,
                    })
                        .then((resp) => {
                            this.$vs.loading.close();
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: "The data was successfully rejected",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            } else {
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }
                            this.getData();
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                }
            });
        },
    },
    mounted() { },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
    filters: {
        formatDate(value) {
            if (value) {
                return moment(value).format("YYYY-MM-DD");
            }
        },
    },
    watch: {
        action(val) {
            this.getData();
            this.mutableAction = val;
        },
        isFilter(val) {
            if (val) {
                this.getData();
                this.$emit("isFilter", false);
                this.filter = val
            }
        },
    },
};
</script>
