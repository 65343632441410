<template>
    <vx-card :title="title">
        <vs-tabs>
            <vs-tab label="New">
                <New></New>
            </vs-tab>
            <vs-tab label="Waiting Approval">
                <Waiting></Waiting>
            </vs-tab>
            <vs-tab label="Approved">
                <Approved></Approved>
            </vs-tab>
            <!-- <vs-tab label="Released">
                <Released></Released>
            </vs-tab> -->
            <vs-tab label="Execution">
                <Executed></Executed>
            </vs-tab>
            <!-- <vs-tab label="Putaway">
                <Putaway></Putaway>
            </vs-tab> -->
            <vs-tab label="Rejected">
                <Rejected></Rejected>
            </vs-tab>
            <vs-tab label="Canceled">
                <Canceled></Canceled>
            </vs-tab>
            <vs-tab label="Inquiry">
                <Inquiry></Inquiry>
            </vs-tab>
            <vs-tab label="Complete">
                <Complete></Complete>
            </vs-tab>
        </vs-tabs>
    </vx-card>
</template>

<script>
import New from "./_tab/tab-new.vue";
import Waiting from "./_tab/tab-waiting.vue";
import Approved from "./_tab/tab-approved.vue";
// import Released from "./_tab/tab-released.vue";
import Executed from "./_tab/tab-executed.vue";
// import Putaway from "./_tab/tab-putaway.vue";
import Rejected from "./_tab/tab-rejected.vue";
import Canceled from "./_tab/tab-canceled.vue";
import Inquiry from "./_tab/tab-inquiry.vue";
import Complete from "./_tab/tab-complete.vue";

export default {
    components: {
        New,
        Waiting,
        Approved,
        // Released,
        Executed,
        // Putaway,
        Rejected,
        Canceled,
        Inquiry,
        Complete
    },
    data() {
        return {
            title: "Work Order",
        };
    },
};
</script>
